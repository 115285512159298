export const columns = [
    {
        title: '签约单',
        dataIndex: 'id',
        ellipsis: true
    },
    {
        title: '楼盘',
        dataIndex: 'houseName',
        ellipsis: true
    },
    {
        title: '经纪人',
        dataIndex: 'brokerName'
    },
    {
        title: '经纪人手机',
        dataIndex: 'report.broker.phone'
    },
    {
        title: '客户',
        dataIndex: 'clientName'
    },
    {
        title: '签约时间',
        dataIndex: 'contractTime',
        ellipsis: true
    },
    {
        title: '个人/公司',
        dataIndex: 'shop'
    },
    {
        title: '银行卡信息',
        dataIndex: 'bank',
        scopedSlots: { customRender: 'bank' }
    },
    {
        title: '到访类型',
        dataIndex: 'report.naturalVisit',
        scopedSlots: { customRender: 'naturalVisit' }
    },
    {
        title: '结佣状态',
        dataIndex: 'commissionStatus',
        width: '150px',
        scopedSlots: { customRender: 'commissionStatus' }
    },
    {
        title: '操作',
        dataIndex: 'action',
        fixed: 'right',
        scopedSlots: { customRender: 'action' }
    }
]

export const orderLogColumns = [
    {
        title: '操作日志',
        dataIndex: 'log',
        width: 600,
        scopedSlots: { customRender: 'log' }
    }
]

export const statusMap = {
    1: {
        status: 'default',
        text: '待结佣',
        color: '#108ee9'
    },
    // 2: {
    //   status: 'processing',
    //   text: '回款中'
    // },
    3: {
        status: 'processing',
        text: '可结佣',
        color: '#2db7f5'
    },
    4: {
        status: 'processing',
        text: '结佣中',
        color: '#2db7f5'
    },
    5: {
        status: 'success',
        text: '已结佣',
        color: '#87d068'
    },
    '-2': {
        status: 'error',
        text: '结佣异议',
        color: '#f50'
    }
}

// 待结佣
export const awaitTabs = [
    // {
    //   title: '回款中',
    //   index: 2,
    //   commissionStatus: 2
    // },
    {
        title: '可结佣',
        index: 3,
        commissionStatus: 3
    },
    {
        title: '结佣异议',
        index: -2,
        commissionStatus: -2
    }
]
// 回款中
export const processTabs = [
    {
        title: '可结佣',
        index: 3,
        commissionStatus: 3
    },
    {
        title: '结佣异议',
        index: -2,
        commissionStatus: -2
    }
]
// 可结佣
export const approveTabs = [
    {
        title: '结佣异议',
        index: -2,
        commissionStatus: -2
    }
]
// 可结佣
export const hasCommissionTabs = [
    {
        title: '结佣异议',
        index: 5,
        commissionStatus: -2
    }
]
// 结佣中
export const commissionTabs = [
    {
        title: '已结佣',
        index: 5,
        commissionStatus: 5
    },
    {
        title: '结佣异议',
        index: -2,
        commissionStatus: -2
    }
]
// 结佣异议
export const errorTabs = [
    {
        title: '结佣异议',
        index: -2,
        commissionStatus: -2
    },
    // {
    //   title: '回款中',
    //   index: 2,
    //   commissionStatus: 2
    // },
    {
        title: '可结佣',
        index: 3,
        commissionStatus: 3
    },
    {
        title: '已结佣',
        index: 5,
        commissionStatus: 5
    }
]
