import request from '@/utils/request'

const orderLogsApi = {
  // get orderLogs info
  orderLogs: '/api/v1/rest/orderLogs',
  findByOrderId: '/api/v1/rest/orderLogs/search/findByOrderId'
}

export function orderLogs(params = {}) {
  let url = orderLogsApi.orderLogs
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}

export function getOrderLogs(id) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'get'
  })
}

export function putOrderLogs(id, data) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'get',
    data
  })
}

export function patchOrderLogs(id, data) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'patch',
    data
  })
}

export function delOrderLogs(id) {
  return request({
    url: orderLogsApi.orderLogs + '/' + id,
    method: 'delete'
  })
}

export function findByOrderId(params) {
  let url = orderLogsApi.findByOrderId
  url += '?' + Object.keys(params).map(item => `${item}=${params[item]}`).join('&')
  return request({
    url,
    method: 'get'
  })
}
