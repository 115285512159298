import request from '@/utils/request'

const reportsApi = {
  // get reports info
  base: '/api/v1',
  reports: '/api/v1/rest/reports',
  query: '/api/v1/report/query',
  findByKeyword: '/api/v1/rest/reports/search/findByKeyword',
  findByStatus: '/api/v1/rest/reports/search/findByStatus',
  findReportsQuery: '/api/v1/report/query'
}

export function reports (params) {
  let url = reportsApi.reports
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function addReports (params) {
  return request({
    url: reportsApi.reports,
    method: 'post',
    data: params
  })
}

export function editReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}`,
    method: 'patch',
    data: params
  })
}

export function delReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}`,
    method: 'delete'
  })
}

export function deleteReports (params) {
  return request({
    url: reportsApi.base + `/report/delete`,
    method: 'get',
    params
  })
}

export function checkOrder (params) {
  return request({
    url: reportsApi.base + `/report/checkOrder`,
    method: 'get',
    params
  })
}

export function findReportsByStatus (params) {
  let url = reportsApi.findByStatus
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function queryReports (params) {
  let url = reportsApi.query
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findReports (params) {
  let url = reportsApi.findByKeyword
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}

export function findByIdReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}`,
    method: 'get'
  })
}

export function inReports (params) {
  return request({
    url: reportsApi.reports + `/${params.id}/${params.name}`,
    method: 'get'
  })
}

export function reportConsultant(id) {
  const url = reportsApi.reports + `/${id}/consultant`
  return request({
    url,
    method: 'get'
  })
}

export function findReportsQuery(params) {
  let url = reportsApi.findReportsQuery
  if (params) {
    url += '?' + Object.keys(params).map(m => `${m}=${params[m]}`).join('&')
  }
  return request({
    url,
    method: 'get'
  })
}
