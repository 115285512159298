<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="搜索">
                <a-input v-model="queryParam.keyword" allow-clear placeholder="请输入搜索信息" />
              </a-form-item>
            </a-col>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span 
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}">
                <a-button type="primary" @click="findByKeyword">查询</a-button>
                <a-button style="margin-left: 8px" @click="reload">重置</a-button>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table 
        ref="table" 
        size="default" 
        :rowKey="record => record.id" 
        :columns="columns" 
        :data="loadData"
        :alert="true"
        :totalCount="totalCount"
        :scroll="defaultScroll"
        showPagination="auto"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <span slot="naturalVisit" slot-scope="text">{{ text | visitFilter }}</span>
        <span slot="bank" slot-scope="text">{{ text | textFilter }}</span>
        <span slot="commissionStatus" slot-scope="text, record">
          <a-popconfirm v-if="record.report.naturalVisit === 1" placement="bottom" ok-text="确定" cancel-text="取消" @confirm="confirm">
            <a-icon slot="icon" type="none" style="color: green;"></a-icon>
            <template slot="title" class="orderConfirm">
              <!-- <p>{{ desc }}</p>
              <p>{{ desc }}</p> -->
              <div style="width: 500px; height: 350px;">
                <a-tabs 
                  size="small" 
                  :activeKey="current" 
                  tab-position="top"
                  :style="{ height: '350px', 'padding-left': 0 }" 
                  @prevClick="callback" 
                  @nextClick="callback"
                  @tabClick="handleTab" 
                  class="manageTitle">
                  <a-tab-pane v-for="item in tabs" :key="item.commissionStatus" :tab="`${item.title}`">
                    <a-form :form="orderForm" :label-col="labelCol" :wrapper-col="wrapperCol">
                      <a-form-item v-if="current === 3" label="佣金(元)">
                        <a-input-number :min="0" v-decorator="['commission', { rules: [{ required: true, message: '请输入佣金' }] }]" />
                      </a-form-item>
                      <a-form-item v-if="current === -2" label="备注">
                        <a-textarea v-decorator="['remark', { rules: [{ required: true, message: '请输入异议' }] }]" :auto-size="{ minRows: 10, maxRows: 10 }" />
                      </a-form-item>
                      <a-form-item v-else label="备注">
                        <a-textarea v-decorator="['remark']" :auto-size="{ minRows: 10, maxRows: 10 }" />
                      </a-form-item>
                    </a-form>
                  </a-tab-pane>
                </a-tabs>
              </div>
            </template>
            <a-tag :color="text | statusTypeFilter" @click="editStatus(record)" class="myPointer">
              <span v-if="text === 1">待结佣</span>
              <span v-if="text === 3">可结佣</span>
              <span v-if="text === 4">结佣中</span>
              <span v-if="text === 5">已结佣</span>
              <span v-if="text === -2">结佣异议</span>
            </a-tag>
          </a-popconfirm>
          <a-tag v-else color="gray">
            <span v-if="text === 1">待结佣</span>
            <span v-if="text === 3">可结佣</span>
            <span v-if="text === 4">结佣中</span>
            <span v-if="text === 5">已结佣</span>
            <span v-if="text === -2">结佣异议</span>
          </a-tag>
        </span>
        <span slot-scope="text">
          <ellipsis :length="4" tooltip>{{ text }}</ellipsis>
        </span>

        <span slot="action" slot-scope="text, record">
          <template>
            <a @click="handleEdit(record)">查看</a>
          </template>
        </span>
      </s-table>

      <a-drawer
        :title="model ? `订单号${model.id}` : '0000000000000000'" 
        placement="right" 
        :width="drawerWidth"
        class="myDescription" 
        :visible="visible" 
        @close="handleCancel">
        <a-card :bordered="false">
          <a-descriptions title="订单信息">
            <a-descriptions-item label="订单编号" span="3">{{
              model.id ? model.id : '0000000000000000'
            }}</a-descriptions-item>
            <a-descriptions-item label="楼盘" span="3">{{
              model.houseName ? model.houseName : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="房号" span="3">{{
              model.contractRoom ? model.contractRoom : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="成交价" span="3">{{
              model.contractPrice | moneyFilter
            }}</a-descriptions-item>
            <a-descriptions-item v-if="model.commission" label="佣金" span="3">{{
              model.commission | moneyFilter
            }}</a-descriptions-item>
            <a-descriptions-item v-else label="暂定佣金" span="3">{{
              model.earnest | moneyFilter
            }}</a-descriptions-item>
            <a-descriptions-item label="签约时间" span="3">{{
              model.contractTime ? model.contractTime : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="认购时间" span="3">{{
              model.createTime ? model.createTime.replace(/\//g, '-') : '-'
            }}</a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px" />
          <a-descriptions title="签约信息">
            <a-descriptions-item label="客户姓名" span="3">{{ model ? model.clientName : '客户' }}</a-descriptions-item>
            <a-descriptions-item label="客户手机" span="3">{{
              model.clientPhone | phoneFilter
            }}</a-descriptions-item>
            <a-descriptions-item v-if="broker" label="经纪人" span="3">{{
              broker.name | textFilter
            }}</a-descriptions-item>
            <a-descriptions-item v-if="broker" label="经纪人手机" span="3">{{
              broker.phone ? phoneFormat(broker.phone) : '--'
            }}</a-descriptions-item>
            <a-descriptions-item label="报备时间" span="3">{{
              reportInfo.createTime ? reportInfo.createTime : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="驻场" span="3">{{ resident ? resident.name : '-' }}</a-descriptions-item>
            <a-descriptions-item label="驻场手机" span="3">{{
              resident ? phoneFormat(resident.phone) : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="置业顾问" span="3">{{
              consultant ? consultant.name : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="认购房号" span="3">{{ model.room ? model.room : '-' }}</a-descriptions-item>
            <a-descriptions-item label="房屋总价" span="3">{{
              model.contractPrice | moneyFilter
            }}</a-descriptions-item>
            <a-descriptions-item label="签约时间" span="3">{{
              model.contractTime ? model.contractTime : '-'
            }}</a-descriptions-item>
            <a-descriptions-item label="合同照片" span="3">
              <!-- model.contractPhotos -->
              <template v-if="model.contractPhotos ? model.contractPhotos.length !== 0 : false">
                <a-card hoverable style="width: 120px" class="inlineBlock" v-for="item in model.contractPhotos" :key="item.id">
                  <!-- <img
                  slot="cover"
                  alt="example"
                  :src="host + item.path"
                /> -->
                  <media :file="item" />
                </a-card>
              </template>
              <template v-else>
                <div>
                  暂无照片
                </div>
              </template>
            </a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px" />
          <a-descriptions v-if="bankInfo.type==2" title="银行卡信息">
            <a-descriptions-item label="银行卡" span="3">公司</a-descriptions-item>
            <a-descriptions-item label="企业名称" span="3">{{ bankInfo.cbankName }}</a-descriptions-item>
            <a-descriptions-item label="卡号" span="3">{{ bankInfo.cbankCode }}</a-descriptions-item>
            <a-descriptions-item label="开户行" span="3">{{ bankInfo.companyName }}</a-descriptions-item>
          </a-descriptions>
          <a-descriptions v-if="bankInfo.type==1" title="银行卡信息">
            <a-descriptions-item label="银行卡" span="3">个人</a-descriptions-item>
            <a-descriptions-item label="姓名" span="3">{{ bankInfo.name }}</a-descriptions-item>
            <a-descriptions-item label="卡号" span="3">{{ bankInfo.bankCode }}</a-descriptions-item>
          </a-descriptions>
          <a-divider style="margin-bottom: 32px" />
          <a-table :columns="orderLogColumns" :data-source="orderLog" :pagination="{ pageSize: 50 }">
            <template slot="log" slot-scope="text, record">
              <a-row>
                <a-col :span="16">
                  {{ record.userName }}设置了结佣状态为
                  <a-tag v-if="record.action === '待结佣'" color="#108ee9">待结佣</a-tag>
                  <a-tag v-if="record.action === '可结佣'" color="#2db7f5">可结佣</a-tag>
                  <a-tag v-if="record.action === '结佣中'" color="#2db7f5">结佣中</a-tag>
                  <a-tag v-if="record.action === '已结佣'" color="#87d068">已结佣</a-tag>
                  <a-tag v-if="record.action === '结佣异议'" color="#f50">结佣异议</a-tag>
                  <span
                    v-if="record.commission">(佣金：{{ record.commission | moneyFilter }})</span>
                </a-col>
                <a-col :span="8" style="text-align: right">
                  <span>{{ record.createTime }}</span>
                </a-col>
              </a-row>
              <a-row style="margin-top: 5px;">
                <a-col :span="24">
                  <span v-if="record.remark" style="color: #1890ff"> 备注：{{ record.remark }} </span>
                </a-col>
              </a-row>
            </template>
          </a-table>
        </a-card>
      </a-drawer>
    </a-card>
  </page-header-wrapper>
</template>

<script>
  import {
    Empty
  } from 'ant-design-vue'
  import {
    STable,
    Ellipsis,
    Media
  } from '@/components'
  import {
    orderReport,
    queryOrders,
    finish,
    back,
    fail,
    money,
    contractResident
  } from '@/api/orders'
  import {
    findByOrderId
  } from '@/api/orderLogs'
  import {
    reportConsultant
  } from '@/api/reports'
  
  import { getBankInfo } from '@/api/broker'
  
  import {
    columns,
    orderLogColumns,
    awaitTabs,
    processTabs,
    approveTabs,
    hasCommissionTabs,
    commissionTabs,
    errorTabs,
    statusMap
  } from './js/manage'

  export default {
    name: 'TableList',
    components: {
      STable,
      Ellipsis,
      Empty,
      Media
    },
    data() {
      return {
        // js
        columns,
        statusMap,
        orderLogColumns,
        awaitTabs,
        processTabs,
        approveTabs,
        commissionTabs,
        hasCommissionTabs,
        errorTabs,
        // img
        file: [],
        model: [],
        houseId: '',
        houseLink: '',
        // create model
        visible: false,
        confirmLoading: false,
        mdl: null,
        broker: null,
        totalCount: 0,
        advanced: false,
        // 查询参数
        queryParam: {},
        // manage
        orderId: '',
        reportId: '',
        current: 1,
        currentTab: 1,
        tabs: awaitTabs,
        reportInfo: {},
        bankInfo: {},
        resident: null,
        consultant: null,
        orderForm: this.$form.createForm(this, {
          name: 'orderForm'
        }),
        buttonWidth: 70,
        labelCol: {
          span: 3
        },
        wrapperCol: {
          span: 20
        },
        orderLog: [],
        loadData: parameter => {
          const param = {
            userId: this.userId,
            status: 1
          }
          const params = this.paramFormat(parameter, param)
          Object.assign(params, this.queryParam)
          return queryOrders(params).then(res => {
            const result = this.resFormat(res)
            result.data.map((item) => {
              console.log('item', item)
              if (item.report && item.report.shop) {
                item.shop = '公司'
                item.bank = (item.report.broker || {}).shopBank
              } else {
                item.shop = '个人'
                item.bank = (item.report.broker || {}).brokerBank
              }
              return item;
            })
            this.totalCount = result.totalCount
            return result
          })
        },
        selectedRowKeys: [],
        selectedRows: []
      }
    },
    created() {
      this.houseId = this.$route.query.id
      this.houseLink = this.$route.query.link
    },
    filters: {
      statusTypeFilter(type) {
        return statusMap[type].color
      },
      visitFilter(type) {
        if (type === 0) {
          return '自然到访'
        } else {
          return '经纪人报备'
        }
      }
    },
    computed: {
      rowSelection() {
        return {
          selectedRowKeys: this.selectedRowKeys,
          onChange: this.onSelectChange
        }
      },
      host() {
        return this.$store.state.oss.host
      },
      serial() {
        return this.$store.state.env.serial
      },
      userId() {
        return this.$store.state.user.info.id
      }
    },
    methods: {
      reload() {
        this.tableHome()
        this.queryParam = {}
        this.$refs.table.refresh()
      },
      handleEdit(record) {
        if (record.commissionStatus === null) {
          record.commissionStatus = -2
        }
        this.model = record
        this.getReportInfo(record.id)
        this.getContractResident(record.id)
        this.getOrderLog(record.id)
        this.getBankInfo(record.brokerId)
        this.visible = true
      },
      getBankInfo(brokerId) {
        const param = {
          brokerId
        }
        getBankInfo(param).then(res => {
          if (res.data) {
             this.bankInfo = res.data
          }
        })
      },
      getReportInfo(id) {
        orderReport(id).then(res => {
          this.reportInfo = res
          this.broker = res.broker
          this.getConsultantInfo(res.id)
        })
      },
      getConsultantInfo(id) {
        reportConsultant(id).then(res => {
          this.consultant = res
        })
      },
      getContractResident(id) {
        contractResident(id).then(res => {
          this.resident = res
        })
      },
      getOrderLog(id) {
        const params = {
          orderId: id,
          sorted: true
        }
        findByOrderId(params).then(res => {
          console.log(res, 'ressss')
          const data = res._embedded.orderLogs.map(item => {
            const params = {
              id: item.id,
              userName: item.userName === undefined ? '暂无信息' : item.userName,
              remark: item.remark === 'undefined' ? '暂无信息' : item.remark,
              commission: item.commission === undefined ? '暂无信息' : item.commission,
              createTime: item.createTime === undefined ? '暂无信息' : item.createTime
            }
            switch (item.commissionStatus) {
              case 1:
                return {
                  ...params,
                  action: '待结佣'
                }
                case 2:
                  return {
                    ...params,
                    action: '回款中'
                  }
                  case 3:
                    return {
                      ...params,
                      action: '可结佣'
                    }
                    case 4:
                      return {
                        ...params,
                        action: '结佣中'
                      }
                      case 5:
                        return {
                          ...params,
                          action: '已结佣'
                        }
                        case -2:
                          return {
                            ...params,
                            action: '结佣异议'
                          }
                          default:
                            return {
                              ...params,
                              action: '待结佣'
                            }
            }
          })
          this.orderLog = data
        })
      },
      handleOk() {
        this.updateOrderInfo()
      },
      updateOrderInfo() {
        this.visible = false
        this.confirmLoading = false
        // 重置表单数据
        this.updateTable('刷新成功', 2)
      },
      handleCancel() {
        this.visible = false
      },
      onSelectChange(selectedRowKeys, selectedRows) {
        this.selectedRowKeys = selectedRowKeys
        this.selectedRows = selectedRows
      },
      toggleAdvanced() {
        this.advanced = !this.advanced
      },
      findByKeyword() {
        this.$refs.table.refresh()
      },
      // MANAGE
      handleTab(val) {
        this.current = val
        this.orderForm.resetFields()
      },
      editStatus(record) {
        const commissionStatus = record.commissionStatus
        this.orderId = record.id
        const {
          awaitTabs,
          processTabs,
          approveTabs,
          commissionTabs,
          errorTabs,
          hasCommissionTabs
        } = this
        switch (commissionStatus) {
          case 1:
            this.switchTab(awaitTabs)
            break
          case 2:
            this.switchTab(processTabs)
            break
          case 3:
            this.switchTab(approveTabs)
            break
          case 4:
            this.switchTab(commissionTabs)
            break
          case 5:
            this.switchTab(hasCommissionTabs)
            break
          default:
            this.switchTab(errorTabs)
            break
        }
      },
      switchTab(data) {
        this.current = data[0].commissionStatus
        this.tabs = data
      },
      confirm() {
        const {
          current
        } = this
        this.orderForm.validateFields((err, values) => {
          const params = {
            id: this.orderId,
            ...values
          }
          if (!err) {
            console.log('Received values of form: ', values)
            console.log(params, 'params')
            console.log(current, 'current')
            switch (current) {
              case 2:
                back(params).then(res => {
                  this.updateTableManage()
                })
                break
              case 3:
                money(params).then(res => {
                  this.updateTableManage()
                })
                break
              case 5:
                finish(params).then(res => {
                  this.updateTableManage()
                })
                break
              case -2:
                fail(params).then(res => {
                  this.updateTableManage()
                })
                break
            }
          } else {
            const params = {
              id: this.orderId,
              ...values
            }
            switch (current) {
              case 3:
                if (params.commission === undefined || params.commission.length === 0) {
                  this.$message.error('请输入佣金')
                  this.orderForm.resetFields()
                  return false
                }
                break
              case -2:
                if (params.remark === undefined || params.remark.length === 0) {
                  this.$message.error('请输入结佣异议')
                  this.orderForm.resetFields()
                  return false
                }
                break
            }
          }
        })
      },
      updateTable(msg = '签约单更新成功。', duration = 3) {
        this.$refs.table.refresh()
        this.$message.success(msg, duration)
      },
      updateTableManage(msg = '签约单更新成功。', duration = 3) {
        this.updateTable()
        this.orderForm.resetFields()
      },
      callback(val) {
        console.log(val)
      }
    }
  }
</script>

<style lang="less" scoped>
  #components-a-popconfirm-demo-placement .ant-btn {
    width: 70px;
    text-align: center;
    padding: 0;
    margin-right: 8px;
    margin-bottom: 8px;
  }

  .orderConfirm {
    width: 300px;
  }

  /deep/ .ant-popover-message-title {
    padding-left: 0 !important;
  }
</style>
